:root {
  --primary:              rgb(7 12 29);
  --secondary:            #e20800;

  --main-red:             #e20800;
  --main-red-light:       #fcf1f1;
  --main-green:           #83ac1b;
  --main-white:           #ffffff;
  --main-light-grey:      #f6f6f6;
}
.chatToolbarDuoToneIcon {
  --fa-primary-color: var(--secondary);
  --fa-secondary-color: #ffffff;
  --fa-secondary-opacity: 1
}
.chatToolbarDuoToneIcon_invert {
  --fa-primary-color: #ffffff;
  --fa-secondary-color: var(--secondary);
  --fa-secondary-opacity: 1
}
.full-height {
  height: 100%;
}
*, body, html {
  padding: 0;
  margin: 0;
}
html {
  font-size: 16px;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}
body {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #f9f9f9;
}

body, html, #root {
  height: 100%;
}

.muicc-colorbox-sliders {
  width: auto !important;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.MuiDialog-container form {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

/* Remove Chrome Autofill-Input-Style */

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* configbar input-fields: light version*/

form.editor-config input,
form.editor-config .MuiFormLabel-root.Mui-focused,
form.editor-config .MuiInputBase-input,
form.editor-config .MuiSelect-icon,
.editor-config .MuiFormControlLabel-label {
  color: white;
}
form.editor-config .MuiOutlinedInput-notchedOutline {
	border-color: rgb(255 255 255 / 0.25);
}
form.editor-config .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
	border-color: rgba(255, 255, 255, 0.87);
}
form.editor-config .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #ffffff;
    border-width: 2px;
}
form.editor-config .MuiFormLabel-root, form.editor-config .MuiRadio-root:not(.Mui-checked),
.editor-config .MuiCheckbox-root:not(.Mui-checked) {
	color: rgba(255, 255, 255, 0.54);
}
form.editor-config fieldset {
	transition: 0.35s border-color ease;
}

form.editor-config .MuiSlider-markLabel {
  color: rgb(255 255 255 / 54%);
}
form.editor-config .MuiSlider-markLabelActive{
  color: rgb(255 255 255 / 87%);
}
.react-swipeable-view-container {
  height: 100%;
}

.MuiPaper-rounded {border-radius: 5px; overflow: hidden;}
.MuiToolbar-regular {background-color: #f9f9f9; border-bottom: 1px solid #E83338;}
.MuiTableSortLabel-root {height: 20px !important}

.MuiButton-iconSizeLarge > *:first-child,
.MuiButton-iconSizeMedium > *:first-child,
.MuiButton-iconSizeSmall > *:first-child {
  font-size: inherit !important;
}

.react-flow {cursor: grab}

.cp_drop_zone {
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  border: 2px dashed #cccccc;
  width: 100%;
  min-height: 200px;
}
.MuiBackdrop-root {
  background-color: rgb(143 143 143 / 40%) !important;
  backdrop-filter: blur(2px);
}
.FormikFormLabel {
  font-size: 0.9rem;
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.65) !important;
}