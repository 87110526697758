.form-wrapper {
    max-width: 768px;
  }
  .main-content {
    background: url(images/orange-bg.jpg) repeat center center fixed;
    background-size: cover;
    background-attachment: scroll;
  }
  .logo {max-width: 235px;}
  label {cursor: pointer;}
  #error, #success {
      font-size: 15px;
      color: #000000;
      line-height: 1em;
  }
  #error {color: #d53237;}
  
  .footer-bar-links {
    display: block;
    text-align: center;
    height: 100%;
    margin: 0px;
    padding: 0px;
  }
  
  .footer-bar-links li {
      display: inline-block;
      padding: 4px;
      cursor: pointer;
  }
  @media screen and (min-width: 1216px) {
    .footer-bar-links {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 auto;
    }
    .footer-bar-links li {
      display: inline-block;
      padding: 0 4px;
    }
    .footer-bar-links li:first-child {
      padding: 0 4px 0 0;
    }
  }
  
  /** Partner-Logos **/
  .partner_logos img {
      float: left;
      width: 50%;
  }
      
   @media (max-width:768px) {
      .partner_logos img {
          float: none;
          width: 100%;
      }	
    }